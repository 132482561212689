<template>
    <div class="home_main">
        <div class="header" id="head">
            <div class="header_fix" v-if="y > 10">
                <div class="header_container_fix">
                    <div class="header_logo"@click="jump('head')">
                        <img  src="../../assets/img/home/about_header.png" height="39" width="135"/>
                    </div>
                    <span class="header_right">
                        <span @click="$router.push('/aboutUs')">关于我们</span>
                        <div class="logo_btn" @click="handleLogin">登录 | 注册</div>
                    </span>
                </div>
            </div>
            <div class="header_container">
                <div class="header_logo">
                    <img @click="jump('head')"  src="../../assets/img/home/home_logo_white.png" height="39" width="135"/>
                    <div class="header_text">
                        <span class="title">专业的竞价推广数据分析平台</span>
                        <span class="text">
                            庞大企业网站数据资源，海量关键词库，实时采集全网竞价广告数据<br>
                            创意素材更丰富，关键词更多更优质，广告投放更高效，快速增加流量，有效提升ROI
                        </span>
                        <div class="search_box">
                            <div class="search_type_group">
                                <div class="search_type_item" :class="searchType =='ad'?'active_type':''" @click="changeSearchType('ad')">查广告创意</div>
                                <div class="search_type_item" :class="searchType =='wd'?'active_type':''" @click="changeSearchType('wd')">查关键词</div>
                                <div class="search_type_item" :class="searchType =='cp'?'active_type':''" @click="changeSearchType('cp')">查推广公司</div>
                                <div class="search_type_item" :class="searchType =='cpt'?'active_type':''" @click="findCompete">查公司竞品</div>
                            </div>
                            <el-input @keypress.enter.native="handleSearch" @input="getSuggestion" @blur="hiddenHistory" size="large" :placeholder="searchType=='ad'?'请输入推广关键词':searchType=='wd'?'请输入关键词':'请输入网站名称/网址、公司名、行业名、推广关键词等'" v-model="keyWord" class="input-with-select">
                                <div slot="append" class="search_btn" @click="handleSearch">查一查</div>
                            </el-input>
                            <div class="search_history" v-show="showInputSuggest && searchSuggestList.length">
                                <div class="search_item" v-for="(item,i) in searchSuggestList" :key="i" @click="selectHistory(item.company_name)">{{item.company_name}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <span class="header_right">
                        <span @click="$router.push('/aboutUs')">关于我们</span>
                        <div class="logo_btn" @click="handleLogin">登录 | 注册</div>
                        <img src="../../assets/img/home/new_head_img.png" alt="">
                </span>
            </div>
        </div>
        <div class="challenge">
            <div class="monitor_cnt">
                <div class="item">
                    <countTo class="count" :startVal='0' :endVal='parseInt(monitorData.record_cnt) || 0' :duration='1500' autoplay></countTo>
                    <span class="text">创意素材</span>
                </div>
                <div class="shu"></div>
                <div class="item">
                    <countTo class="count" :startVal='0' :endVal='parseInt(monitorData.word_cnt) || 0' :duration='1500' autoplay></countTo>
                    <span class="text">关键词</span>
                </div>
                <div class="shu"></div>
                <div class="item">
                    <countTo class="count" :startVal='0' :endVal='parseInt(monitorData.domain_cnt) || 0' :duration='1500' autoplay></countTo>
                    <span class="text">推广域名</span>
                </div>
                <div class="shu"></div>
                <div class="item">
                    <countTo class="count" :startVal='0' :endVal='parseInt(monitorData.company_cnt) || 0' :duration='1500' autoplay></countTo>
                    <span class="text">推广公司</span>
                </div>
            </div>
            <div class="challenge_title">您是否也经常遇到这些挑战？</div>
            <div class="item_group">
                <div class="challenge_item">
                    <img src="../../assets/img/home/challenge_creativity.png" height="55" width="51"/>
                    <span class="title">关键词 文案创意想得头疼?</span>
                    <span class="text">关键词库优化、管理没思路</span>
                    <span class="text">创意枯竭，想不出好文案</span>
                    <span class="text">同行优质创意/素材寻找收集困难</span>
                </div>
                <div class="challenge_item">
                    <img src="../../assets/img/home/challenge_ad.png" height="51" width="51"/>
                    <span class="title">广告流量竞争太激烈?</span>
                    <span class="text">竞品太多，连对手是谁都摸不清</span>
                    <span class="text">竞品主攻业务、投放平台、投放策略不清楚</span>
                    <span class="text">平台/广告形式多，试错成本高</span>
                </div>
                <div class="challenge_item">
                    <img src="../../assets/img/home/challenge_trend.png" height="45" width="47"/>
                    <span class="title">行业市场趋势难掌握?</span>
                    <span class="text">竞品公司业务动向难察觉</span>
                    <span class="text">新鲜热点、创意掌握不及时</span>
                    <span class="text">行业广告投放趋势不了解</span>
                </div>
            </div>
        </div>
        <div class="step">
            <div class="step_title">AI竞投  助您知己知彼决胜千里</div>
            <div class="step_group">
                <div class="step_item">
                    <div class="item_left">
                        <img src="../../assets/img/home/01_img.png" height="318" width="576"/>
                    </div>
                    <div class="item_right flex_column">
                        <span class="title">
                            <span>查广告—丰富素材优化创意</span>
                        </span>
                        <span class="text">全平台广告创意一键查询</span>
                        <span class="text">高质量竞价词批量挖掘</span>
                        <span class="text">多维度查找推广公司</span>
                        <myButton
                            width="130"
                            height="40"
                            @click="handleLogin"
                            type="primary">
                            <span slot="text">立即体验</span>
                        </myButton>
                    </div>
                </div>
                <div class="step_item">
                    <div class="item_left flex_column" style="margin-left: 0">
                        <span class="title">
                            <span>找竞品—对比分析监测动态</span>
                        </span>
                        <span class="text">智能匹配竞品，分析竞品推广策略</span>
                        <span class="text">掌握竞品关键词，感知竞品创意、落地页</span>
                        <span class="text">实时监测竞品、关键词，定时推送通知</span>
                        <myButton
                            width="130"
                            height="40"
                            @click="handleLogin"
                            type="primary">
                            <span slot="text">立即体验</span>
                        </myButton>
                    </div>
                    <div class="item_right">
                        <img src="../../assets/img/home/02_img.png" height="317" width="577"/>
                    </div>
                </div>
                <div class="step_item">
                    <div class="item_left">
                        <img src="../../assets/img/home/03_img.png" height="318" width="576"/>
                    </div>
                    <div class="item_right flex_column">
                        <span class="title">
                            <span>借鉴优化—增加流量提升ROI</span>
                        </span>
                        <span class="text">创意素材更丰富</span>
                        <span class="text">关键词更多更优质</span>
                        <span class="text">广告优化更高效，竞价策略更明智</span>
                        <myButton
                            width="130"
                            height="40"
                            @click="handleLogin"
                            type="primary">
                            <span slot="text">立即体验</span>
                        </myButton>
                    </div>
                </div>
            </div>
        </div>
        <div class="advantage">
            <div class="title">AI竞投特色与优势</div>
            <div class="img_group">
                <img src="../../assets/img/home/advantage1.png" height="229" width="289"/>
                <img src="../../assets/img/home/advantage2.png" height="229" width="289"/>
                <img src="../../assets/img/home/advantage3.png" height="229" width="289"/>
                <img src="../../assets/img/home/advantage4.png" height="229" width="289"/>
            </div>
        </div>
        <div class="evaluate">
            <div class="title">客户评价</div>
            <el-carousel :interval="50000000" height="320px" indicator-position="outside" arrow="always">
                <el-carousel-item>
                    <div class="carousel_item">
                        <img src="../../assets/img/home/evaluate_01.png" height="100" width="100"/>
                        <span class="evaluate_title">上海某牙科医院客户</span>
                        <span class="evaluate_text">牙科生意越来越火，大家都在组织各种营销活动来吸引顾客。为了想出好创意，每天不知道烧死多少脑细胞。而且有时候辛苦想好的创意未必能有好的结果，时间、资金的投入打水漂。自从有了AI竞投，我们不仅可以方便地查看同行竞品的广告创意，甚至连其他医疗类的广告套路、热门创意也可以轻松获取。视野开阔了，创意不发愁。活动快速上线，抢占先机。</span>
                    </div>
                </el-carousel-item>
                <el-carousel-item>
                    <div class="carousel_item">
                        <img src="../../assets/img/home/evaluate_02.png" height="100" width="100"/>
                        <span class="evaluate_title">深圳某教育机构客户</span>
                        <span class="evaluate_text">一线城市的市场已经成为红海，厮杀激烈。我们在深圳站稳了脚跟，还想开拓二线、三线城市的市场。但是由于对当地行情不熟悉、甚至对手是谁都不清楚。利用AI竞投，我们很快就掌握了当地主要竞品和主推业务，极大地降低了调研成本，而且在投放广告时，我们利用这些已知信息突出自己的优势，精准切入，分流对手客户资源，慢慢在当地站稳了脚跟。</span>
                    </div>
                </el-carousel-item>
                <el-carousel-item>
                    <div class="carousel_item">
                        <img src="../../assets/img/home/evaluate_03.png" height="100" width="100"/>
                        <span class="evaluate_title">北京某医美机构客户</span>
                        <span class="evaluate_text">行业内竞争激烈，广告投放年年在涨，但回报率基本不变，顾客增长也停滞不前，甚至有下降趋势，投入很多也没找到问题点。通过AI竞投，竞品的投放策略和广告创意都被我们知悉，给了我们很大的启发，调整广告投放后，数据开始增长，回报率开始慢慢上升了。</span>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="open_vip">
            <div class="title">免费试用名额限时领取中</div>
            <div class="btn" @click="handleLogin">立即抢占名额</div>
            <div class="bottom">
                <img src="../../assets/img/home/icon_contact_us.png"/>
                <span>客服电话：4006-173-173</span>
                <span>客服邮箱：aicha@pingansec.com</span>
            </div>
        </div>
        <div class="right">
            <span style="position: relative">Copyright© 2021-2024 上海凭安网络科技有限公司 AI竞投（aijingtou.com）. All Rights Reserved.  <span  @click="toLink()">沪ICP备12039960号-38</span></span>
        </div>

        <el-dialog
            title="提示"
            :before-close="handleClose"
            custom-class="head_jurisdiction"
            :visible.sync="dialogVisible">
            <div class="container">
                <div>您尚未登录，请登录后查询。</div>
                <div style=" margin-top: 13px">新注册用户即可获得<span style="color: #ff6000;">3</span>天试用权限</div>
            </div>
            <div class="footer_btn">
                <myButton type="secondary" width="80" height="33" class="button" style="margin-right: 11px;" @click="handleClose">
                    <span slot="text">取消</span>
                </myButton>
                <myButton type="primary" width="80" height="33" class="button" @click="$router.push('/login')">
                    <span slot="text">登录/注册</span>
                </myButton>
            </div>
        </el-dialog>
    </div>
</template>

<script>
  import myButton from "../../components/myButton/myButton";
  import PinganAna from 'pingansec-vue-ana'
  import * as apiDataView from "@/api/dataView"
  import countTo from 'vue-count-to';
  import {
    get_user_info
  } from '@/api/common'
  import * as api from "@/api/home"
  import * as search from "@/api/search"
  export default {
    name: "home",
    components: {myButton, countTo},
    data() {
      var validatorPhone = function (rule, value, callback) {
        if (value === '') {
          callback(new Error('手机号不能为空'))
        } else if (!/^1\d{10}$/.test(value)) {
          callback(new Error('手机号格式错误'))
        } else {
          callback()
        }
      };
      var validatorName = function (rule, value, callback) {
        if (value.length < 2 || value.length > 20) {
          callback(new Error('公司名称应在2-20个字之间'))
        } else {
          callback()
        }
      };
      return {
        y: 0,
        searchType: 'ad',
        monitorData: {},
        infoForm: {
          company_name: '',
          user_name: '',
          phone: '',
          source: 'pc',
        },
        formRules: {
          company_name: [
            { required: true, validator: validatorName, trigger: 'blur' },
          ],
          phone: [
            { required: true, validator: validatorPhone, trigger: 'blur' },
          ],
        },
        disable: false,
        dialogVisible: false,
        keyWord: '',
        showInputSuggest: false,
        searchSuggestList: [],
      }
    },
    created() {
      this.isMobile();
      if(this.$route.query.fdhgjksdh) {
        localStorage.setItem('ajt_source', this.$route.query.fdhgjksdh)
      }
      this.getDefaultCnt()
    },
    mounted() {
      document.querySelector('body').onscroll = this.handleScroll;
    },
    methods: {
      changeSearchType(type) {
        if(this.searchType != type) {
          this.searchType = type
          this.showInputSuggest = false
        }
      },
      hiddenHistory() {
        setTimeout(() => {
          this.showInputSuggest = false
        },500)
      },
      selectHistory(word) {
        this.keyWord = word;
        this.showInputSuggest = false
        this.handleSearch();
      },
      getSuggestion() {
        if(this.keyWord && this.searchType == 'cp') {
          this.showInputSuggest = true ;
          let params = {
            keyword: this.keyWord,
            type: 1
          };
          search.get_ad_compete_list_search_index(params).then(res => {
            if(res.data.result_code == 0) {
              this.searchSuggestList = res.data.data;
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        }
      },
      handleClose() {
        this.dialogVisible = false;
      },
      handleLogin() {
        PinganAna.fire(3035)
        get_user_info().then(res => {
          if(res.data.result_code == 0){
            this.$router.push('/box/dataView');
          } else {
            this.$router.push('/login');
          }
        })
      },
      findCompete() {
        this.searchType = 'cpt'
        get_user_info().then(res => {
          if(res.data.result_code == 0){
            this.$router.push('/box/findCompetitive');
          } else {
            this.dialogVisible = true
          }
        })
      },
      handleSearch() {
        if(this.keyWord) {
          get_user_info().then(res => {
            if(res.data.result_code == 0){
              let path = '/box/search'
              switch (this.searchType) {
                case "ad":
                  path += '/findAdvertising'
                  break;
                case "wd":
                  path += '/findWord'
                  break;
                case "cp":
                  path += '/findCompany'
                  break;
              }
              this.$router.push({
                path: path,
                query: {
                  word: this.keyWord,
                }
              });
            } else {
              this.dialogVisible = true
            }
          })
        } else {
          this.$message({
            type: 'error',
            message: '关键词不能为空！'
          })
        }

      },
      toLink() {
        window.open('https://beian.miit.gov.cn/#/Integrated/index','_blank')
      },
      handleScroll(e) {
        this.y = e.target.scrollingElement.scrollTop;
      },
      jump(id) {
        var element = document.getElementById(id);
        element.scrollIntoView({behavior: "smooth"});
      },
      isMobile() {
        let flag = navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        );
        if(flag) {
          this.$router.push('/h5');
        } else {
          PinganAna.fire(3028)
        }
      },
      submitForm() {
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            api.add_message(this.infoForm).then(res => {
              if(res.data.result_code == 0) {
                this.$message({
                  type: 'success',
                  message: '发送成功，我们会尽快联系您'
                })
                this.disable = true;
                PinganAna.fire(3032)
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.message
                })
              }
            })
          }
        });
      },
      getDefaultCnt() {
        let p = new Promise((resolve, reject) => {
          apiDataView.ad_statistics().then(res => {
            if(res.data.result_code == 0) {

              resolve(res.data.data);
              this.monitorData = res.data.data
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        })
        return p;
      }
    }
  }
</script>

<style scoped lang="scss">
    .home_main {
        width: 100%;
        font-size: 16px;
        .header {
            width: 100%;
            height: 512px;
            background: url("../../assets/img/home/head_bg.png");
            background-size: cover;
            position: relative;
            padding-top: 28px;
            .header_fix {
                width: 100%;
                height: 70px;
                background-color: #ffffff;
                box-shadow: 0 0 10px rgba(235, 239, 242, 0.5);
                position: fixed;
                top: 0;
                z-index: 10 ;
                .header_container_fix {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 1200px;
                    height: 100%;
                    margin: 0 auto;
                    .header_logo {
                        position: relative;
                        cursor: pointer;
                    }
                    .header_right {
                        color: #444444;
                        .logo_btn {
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            width: 90px;
                            height: 33px;
                            background-color: #ffffff;
                            border-radius: 6px;
                            border: solid 1px #318bf9;
                            box-sizing: border-box;
                            font-size: 14px;
                            cursor: pointer;
                            color: #1f81f8;
                            &:hover {
                                background-color: #e9f3fd;
                            }
                            &:active {
                                background-color: #f6f9fe;
                            }
                        }
                        span {
                            margin: 0 39px;
                            cursor: pointer;
                        }
                    }
                }
            }
            .header_container {
                height: 50px;
                width: 1200px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: rgba(31, 129, 248, 0);
                margin: 0 auto;
                .header_logo {
                    position: relative;
                    .header_text {
                        position: absolute;
                        top: 123px;
                        left: 0;
                        white-space:nowrap;
                        display: inline-flex;
                        flex-flow: column;
                        color: #ffffff;
                        .title {
                            font-size: 45px;
                            /*font-weight: bold;*/
                        }
                        .text {
                            opacity: 0.7;
                            font-size: 16px;
                            margin-top: 32px;
                            margin-bottom: 55px;
                            line-height: 24px;
                        }
                        .sub_text {
                            font-size: 24px;
                            margin-bottom: 10px;
                        }
                        .text_btn {
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            width: 135px;
                            height: 50px;
                            background-color: #ffffff;
                            border-radius: 6px;
                            border: solid 1px #1f81f8;
                            box-sizing: border-box;
                            font-size: 14px;
                            cursor: pointer;
                            color: #1f81f8;
                            margin-top: 26px;
                            &:hover {
                                background-color: #e9f3fd;
                            }
                            &:active {
                                background-color: #f6f9fe;
                            }
                        }
                        .search_box {
                            .search_type_group {
                                margin-bottom: 13px;
                                .search_type_item {
                                    color: #ffffff;
                                    font-size: 16px;
                                    cursor: pointer;
                                    padding: 9px 11px;
                                    display: inline-block;
                                    border-radius: 5px;
                                    position: relative;
                                    margin-right: 15px;
                                }
                                .active_type {
                                    background-color: #28a745;
                                    &:after {
                                        content: '';
                                        position: absolute;
                                        bottom: -6px;
                                        left: calc(50% - 8px);
                                        display: inline-block;
                                        border-left: 8px solid transparent;
                                        border-right: 8px solid transparent;
                                        border-top: 6px solid #28a745;
                                    }
                                }
                            }
                            .search_history {
                                width: 506px;
                                background-color: #ffffff;
                                box-shadow: 0px 4px 10px 0px rgba(204, 204, 204, 0.3);
                                border-radius: 6px;
                                border: solid 1px #ebeff2;
                                position: absolute;
                                max-height: 174px;
                                overflow-y: auto;
                                z-index: 10;
                                &::-webkit-scrollbar {
                                    display: none;
                                }
                                .search_item {
                                    height: 32px;
                                    font-size: 14px;
                                    color: #333333;
                                    padding-left: 15px;
                                    display: flex;
                                    align-items: center;
                                    cursor: pointer;
                                    &:hover {
                                        background-color: #e8f2fe;
                                        color: #1f81f8;
                                    }
                                }
                            }
                        }
                    }
                }
                .header_right {
                    width: 191px;
                    position: relative;
                    color: #ffffff;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .logo_btn {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        width: 90px;
                        height: 33px;
                        background-color: #1f81f8;
                        border-radius: 6px;
                        border: solid 1px #ffffff;
                        box-sizing: border-box;
                        font-size: 14px;
                        cursor: pointer;
                        &:hover {
                            background-color: #1c74de;
                        }
                        &:active {
                            background-color: #368ef8;
                        }
                    }
                    span {
                        cursor: pointer;
                    }
                    img {
                        width: 491px;
                        /*height: 378px;*/
                        position: absolute;
                        right: 43px;
                        top: 104px;
                    }
                }
            }
        }
        .challenge {
            width: 100%;
            background-color: #ffffff;
            padding-top:  140px;
            padding-bottom:  70px;
            position: relative;
            .monitor_cnt {
                width: 1026px;
                display: inline-flex;
                background-color: #ffffff;
                box-shadow: 1px 2px 15px 0px
                rgba(153, 153, 153, 0.2);
                border-radius: 12px;
                padding: 29px 87px;
                justify-content: space-around;
                position: absolute;
                left: calc(50% - 600px);
                top: -55px;
                .item {
                    display: inline-flex;
                    flex-direction: column;
                    align-items: center;
                    .count {
                        color: #1f81f8;
                        margin-bottom: 17px;
                        font-size: 24px;
                        font-weight: bold;
                    }
                    .text {
                        color: #333333;
                        font-size: 16px;
                    }
                }
                .shu {
                    display: inline-block;
                    background-color: #e0eefe;
                    width: 1px;
                    height: 38px;
                    margin: auto 0;
                }
            }
            .challenge_title {
                font-size: 30px;
                color: #333333;
                text-align: center;
                margin-bottom: 89px;
            }
            .item_group {
                display: flex;
                justify-content: space-around;
                margin: 0 auto;
                width: 1136px;
                .challenge_item {
                    display: inline-flex;
                    flex-flow: column;
                    align-items: center;
                    .title {
                        font-size: 30px;
                        color: #333333;
                        margin: auto 0;
                        margin-top: 35px;
                        margin-bottom: 26px;
                    }
                    .text {
                        color: #999999;
                        margin-bottom: 10px;
                        font-size: 16px;
                    }
                }
            }
        }
        .step {
            width: 100%;
            height: 1350px;
            background-color: #fff;
            padding-top: 70px;
            .step_title {
                font-size: 30px;
                color: #333333;
                text-align: center;
                margin-bottom: 20px;
            }
            .step_group {
                display: flex;
                flex-flow: column;
                justify-content: space-around;
                height: 100%;
                .step_item {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    width: 1200px;
                    margin: 0 auto;
                    .flex_column {
                        display: inline-flex;
                        flex-flow: column;
                        align-self: flex-start;
                        margin-left: 95px;
                        .primary {
                            margin-top: 25px;
                            font-size: 16px;
                        }
                    }
                    .title {
                        font-size: 30px;
                        color: #333333;
                        margin-top: 42px;
                        margin-bottom: 22px;
                        display: flex;
                    }
                    .text {
                        font-size: 16px;
                        color: #999999;
                        margin-bottom: 10px;
                    }
                }
            }
        }
        .advantage {
            width: 100%;
            background-color: #fff;
            padding-top: 106px;
            padding-bottom: 80px;
            .title {
                font-size: 30px;
                color: #333333;
                text-align: center;
                margin-bottom: 47px;
            }
            .img_group {
                width: 1180px;
                margin: 0 auto;
                display: flex;
                justify-content: space-around;
                img {
                    transition: all 0.5s;
                    &:hover {
                        transform: scale(1.1, 1.1);
                    }
                }
            }
        }
        .evaluate {
            width: 100%;
            background: url("../../assets/img/home/evaluate_bg.png") no-repeat center;
            background-size: 808px 396px;
            background-color: #fff;
            padding-top: 75px;
            padding-bottom: 89px;
            .title {
                font-size: 30px;
                color: #333333;
                text-align: center;
                margin-bottom: 66px;
            }
            .el-carousel {
                max-width: 1200px;
                margin: 0 auto;
                .carousel_item {
                    width: 740px;
                    height: 320px;
                    background-color: #ffffff;
                    /*border: 1px solid #333;*/
                    box-shadow: 1px 2px 9px 0px
                    rgba(0, 0, 0, 0.08);
                    border-radius: 9px;
                    display: flex;
                    flex-flow: column;
                    align-items: center;
                    justify-content: space-around;
                    padding: 0 45px;
                    margin: 0 auto;
                    .evaluate_title {
                        color: #333333;
                        font-size: 27px;
                    }
                    .evaluate_text {
                        color: #999999;
                        line-height: 27px;
                    }
                }
            }
        }
        .open_vip {
            display: flex;
            flex-flow: column;
            align-items: center;
            width: 100%;
            background: url("../../assets/img/home/free_bg.png")no-repeat center;
            background-size: cover;
            border-bottom: 1px solid rgba(255, 255, 255, 0.15);
            .title {
                font-size: 30px;
                color: #ffffff;
                margin-top: 66px;
                margin-bottom: 26px;
            }
            .btn {
                width: 236px;
                background-color: #ffffff;
                font-size: 16px;
                color: #1f81f8;
                padding: 12px;
                border-radius: 3px;
                text-align: center;
                cursor: pointer;
                /*&:hover {*/
                /*    background-color: #1f81f8;*/
                /*    color: #fff;*/
                /*}*/
            }
            .bottom {
                width: 494px;
                height: 34px;
                background-color: rgba(31, 129, 248, .1);
                border-radius: 40px;
                margin-top: 33px;
                margin-bottom: 57px;
                font-size: 14px;
                color: #fff;
                display: flex;
                align-items: center;
                img {
                    width: 30px;
                    height: 30px;
                }
                span {
                    margin-left: 8px;
                    font-size: 14px;
                }
            }
        }
        .right {
            width: 100%;
            height: 75px;
            background-color: #fff;
            font-size: 14px;
            color: #999999;
            text-align: center;
            line-height: 75px;
            cursor: pointer;
        }
    }
</style>

<style lang="scss">
    .home_main {
        .el-input {
            .el-input__inner {
                height: 50px;
            }
            .el-input-group__append {
                background-color: #28a745;
                font-size: 18px;
                color: #ffffff;
                cursor: pointer;
                border: 1px solid #28a745;
                &:hover {
                    background-color: rgba(40, 167, 69, 0.8);
                }
            }
        }
        .el-carousel__arrow--left {
            left: 170px;
        }
        .el-carousel__arrow--right {
            right: 170px;
        }
        .el-carousel__arrow {
            width: 30px;
            height: 30px;
            background-color: rgba(153, 153, 153,.8);
            i {
                color: #ffffff;
            }
            &:hover {
                background-color: #1f81f8;
                i {
                    color: #fff;
                }
            }
        }
        .el-carousel--horizontal {
            padding-top: 10px;
        }
        .el-carousel__item {
            overflow: unset;
        }
        .el-carousel__indicators--outside {
            opacity: 0;
            button {
                background-color: #1f81f8;
            }
        }
        .el-form-item__content {
            line-height: unset;
        }
    }
    .head_jurisdiction {
        width: 435px;
        background-color: #ffffff;
        border-radius: 5px;
        border: solid 1px #ebeff2;
        .el-dialog__header {
            font-size: 16px;
            color: #333333;
        }
        .el-dialog__body {
            padding: 30px 20px 20px 20px;
            .container {
                font-size: 14px;
                color: #333333;
            }
            .footer_btn {
                margin-top: 29px;
                text-align: right;
            }
        }
    }
</style>
